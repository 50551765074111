<template>
	<div>
		<div class="bottom-div-mobile posRelative" v-if="isMobile">
			<img src="../assets/img/bg_yw.png" class="posAbsoluteAll">
			<div class="column-me posRelative">
				<div class="main-content flex1">
					<img src="../assets/img/logo.png" class="img-logo">
					<div class="column-me margin-top30">
						<div class="width100Per">
							<el-input placeholder="留言标题" style="width: 100%;" clearable v-model="title"
								v-if="language==='0'"></el-input>
							<el-input placeholder="Message title" style="width: 100%;" clearable v-model="title"
								v-if="language==='1'"></el-input>
						</div>
						<div class="width100Per margin-top20">
							<el-input type="textarea" placeholder="留言内容" style="width: 100%;" clearable resize="none"
								v-model="content" v-if="language==='0'">
							</el-input>
							<el-input type="textarea" placeholder="Message content" style="width: 100%;" clearable
								resize="none" v-model="content" v-if="language==='1'">
							</el-input>
						</div>
						<div class="width100Per margin-top20">
							<el-input placeholder="联系邮箱" style="width: 100%;" clearable v-model="email"
								v-if="language==='0'"></el-input>
							<el-input placeholder=" Contact email" style="width: 100%;" clearable v-model="email"
								v-if="language==='1'"></el-input>
						</div>
						<div class="width100Per row-me row-center margin-top20">
							<el-input placeholder="验证码" class="flex1" clearable v-model="code" v-if="language==='0'">
								<template #append>
									<div class="pointer" @click="refreshCode">
										<!--验证码组件-->
										<s-identify :identifyCode="identifyCode" :fontSizeMin="45" :fontSizeMax="45">
										</s-identify>
									</div>
								</template>
							</el-input>
							<el-input placeholder="Verification Code" clearable v-model="code" v-if="language==='1'">
								<template #append>
									<div class="pointer" @click="refreshCode">
										<!--验证码组件-->
										<s-identify :identifyCode="identifyCode" :fontSizeMin="45" :fontSizeMax="45">
										</s-identify>
									</div>
								</template>
							</el-input>
						</div>
						<div class="row-me row-center flex-end1 margin-top40">
							<div class="btn-submit" @click="clickSubmit" v-if="language==='0'">提交</div>
							<div class="btn-submit" @click="clickSubmit" v-if="language==='1'">Submit</div>
						</div>
					</div>

					<div class="column-me title1 margin-top50">
						<div v-if="language==='0'">
							<div class="row-me">
								<div>公司地址：</div>
								<div class="flex1">{{detailItem.address}}</div>
							</div>
							<div class="row-me margin-top10">
								<div>电&emsp;&emsp;话：</div>
								<div class="flex1">{{detailItem.phone}}</div>
							</div>
							<div class="row-me margin-top10">
								<div>邮&emsp;&emsp;编：</div>
								<div class="flex1">{{detailItem.zip_code}}</div>
							</div>
							<div class="row-me margin-top10">
								<div>招&emsp;&emsp;聘：</div>
								<div class="flex1">{{detailItem.recruit_email}}</div>
							</div>
							<div class="row-me margin-top10">
								<div>项目提交：</div>
								<div class="flex1">{{detailItem.project_email}}</div>
							</div>
						</div>
						<div v-if="language==='1'">
							<div class="row-me">
								<div>Company Address：</div>
								<div class="flex1">{{detailItem.address}}</div>
							</div>
							<div class="row-me margin-top10">
								<div>Telephone：</div>
								<div class="flex1">{{detailItem.phone}}</div>
							</div>
							<div class="row-me margin-top10">
								<div>Postal Code：</div>
								<div class="flex1">{{detailItem.zip_code}}</div>
							</div>
							<div class="row-me margin-top10">
								<div>Recruitment Email：</div>
								<div class="flex1">{{detailItem.recruit_email}}</div>
							</div>
							<div class="row-me margin-top10">
								<div>Project Submission Email：</div>
								<div class="flex1">{{detailItem.project_email}}</div>
							</div>
						</div>
						<div class="column-me row-center margin-top30">
							<img :src="detailItem.qrcode" class="img-code">
							<div class="margin-top20" v-if="language==='0'">扫一扫，关注公众号</div>
							<div class="margin-top20" v-if="language==='1'">
								Sweep and pay attention to official account
							</div>
						</div>
					</div>
				</div>
				<div class="line"></div>
				<div class="copyright" v-if="language==='0'">
					Copyright 2022 © 健壹资本管理有限公司<br>沪ICP备2022002666号-4
				</div>
				<div class="copyright" v-if="language==='1'">
					Copyright 2022 © Jianyi Capital Management Co., Ltd.<br>Shanghai ICP Preparation No.2022002666-4
				</div>
			</div>
		</div>
		<div class="bottom-div height100Per posRelative" v-else>
			<img src="../assets/img/bg_yw.png" class="posAbsoluteAll">
			<div class="posAbsoluteAll column-me">
				<div class="main-content row-me flex1">
					<div class="column-me title1">
						<img src="../assets/img/logo.png" class="img-logo">
						<span class="title2 margin-top50" v-if="language==='0'">
							公司地址：{{detailItem.address}}<br>
							电&emsp;&emsp;话：{{detailItem.phone}}<br>
							邮&emsp;&emsp;编：{{detailItem.zip_code}}<br>
							招&emsp;&emsp;聘：{{detailItem.recruit_email}}<br>
							项目提交：{{detailItem.project_email}}<br>
						</span>
						<span class="title2 margin-top50" v-if="language==='1'">
							Company Address：{{detailItem.address}}<br>
							Telephone：{{detailItem.phone}}<br>
							Postal Code：{{detailItem.zip_code}}<br>
							Recruitment Email：{{detailItem.recruit_email}}<br>
							Project Submission Email：{{detailItem.project_email}}<br>
						</span>
						<div class="flex1"></div>
						<div class="column-me row-center" style="width: fit-content;" v-if="language==='0'">
							<img :src="detailItem.qrcode" class="img-code">
							<div class="title1 margin-top20">扫一扫，关注公众号</div>
						</div>
						<div class="column-me" style="width: fit-content;" v-if="language==='1'">
							<img :src="detailItem.qrcode" class="img-code">
							<div class="title1 margin-top20">
								Sweep and pay attention to official account
							</div>
						</div>
					</div>
					<div class="margin-left150 flex1 column-me">
						<div class="width100Per">
							<el-input placeholder="留言标题" style="width: 100%;" clearable v-model="title"
								v-if="language==='0'"></el-input>
							<el-input placeholder="Message title" style="width: 100%;" clearable v-model="title"
								v-if="language==='1'"></el-input>
						</div>
						<div class="width100Per margin-top20">
							<el-input type="textarea" placeholder="留言内容" style="width: 100%;" clearable resize="none"
								v-model="content" v-if="language==='0'">
							</el-input>
							<el-input type="textarea" placeholder="Message content" style="width: 100%;" clearable
								resize="none" v-model="content" v-if="language==='1'">
							</el-input>
						</div>
						<div class="width100Per margin-top20">
							<el-input placeholder="联系邮箱" style="width: 100%;" clearable v-model="email"
								v-if="language==='0'"></el-input>
							<el-input placeholder=" Contact email" style="width: 100%;" clearable v-model="email"
								v-if="language==='1'"></el-input>
						</div>
						<div class=" width100Per margin-top20">
							<el-input placeholder="验证码" style="width: 100%;" clearable v-model="code"
								v-if="language==='0'">
								<template #append>
									<div class="pointer" @click="refreshCode">
										<!--验证码组件-->
										<s-identify :identifyCode="identifyCode" :fontSizeMin="45" :fontSizeMax="45"
											:contentWidth="140" :contentHeight="50">
										</s-identify>
									</div>
								</template>
							</el-input>
							<el-input placeholder="Verification Code" style="width: 100%;" clearable v-model="code"
								v-if="language==='1'">
								<template #append>
									<div class="pointer" @click="refreshCode">
										<!--验证码组件-->
										<s-identify :identifyCode="identifyCode" :fontSizeMin="45" :fontSizeMax="45"
											:contentWidth="140" :contentHeight="50">
										</s-identify>
									</div>
								</template>
							</el-input>
						</div>
						<div class="row-me row-center flex-end1 margin-top40">
							<div class="btn-submit" @click="clickSubmit" v-if="language==='0'">提交</div>
							<div class="btn-submit" @click="clickSubmit" v-if="language==='1'">Submit</div>
						</div>
					</div>
				</div>
				<div class="line"></div>
				<div class="copyright" v-if="language==='0'">
					Copyright 2022 © 健壹资本管理有限公司 | 沪ICP备2022002666号-4
				</div>
				<div class="copyright" v-if="language==='1'">
					Copyright 2022 © Jianyi Capital Management Co., Ltd. | Shanghai ICP Preparation No.2022002666-4
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SIdentify from '../components/sidentify/sidentify.vue'
	export default {
		components: {
			SIdentify
		},
		data() {
			return {
				title: '',
				content: '',
				email: '',
				code: '',
				identifyCodes: "1234567890abcdefghijklmnopqrstuvwxyz",
				identifyCode: "",
				detailItem: {},
				language: '', //0中文，1英文
				isMobile: '', //0电脑，1手机
			}
		},
		// html加载完成之前
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			this.language = localStorage.getItem("language")
		},
		// html加载完成后执行。
		mounted() {
			this.refreshCode()
			this.getData()
		},
		// 事件方法执行
		methods: {
			//验证码
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min);
			},
			refreshCode() {
				this.identifyCode = "";
				this.makeCode(this.identifyCodes, 4);
			},
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					this.identifyCode += this.identifyCodes[
						this.randomNum(0, this.identifyCodes.length)
					];
				}
			},
			//提交
			clickSubmit() {
				if (this.title === '') {
					if (this.language === '0') {
						this.$message.error("请输入留言标题")
					} else if (this.language === '1') {
						this.$message.error("Please enter the message title")
					}
					return
				}
				if (this.content === '') {
					if (this.language === '0') {
						this.$message.error("请输入留言内容")
					} else if (this.language === '1') {
						this.$message.error("Please enter the message content")
					}
					return
				}
				if (this.email === '') {
					if (this.language === '0') {
						this.$message.error("请输入联系邮箱")
					} else if (this.language === '1') {
						this.$message.error("Please enter the contact email")
					}
					return
				}
				if (this.code === '') {
					if (this.language === '0') {
						this.$message.error("请输入验证码")
					} else if (this.language === '1') {
						this.$message.error("Please enter the verification code")
					}
					return
				}
				if (this.code !== this.identifyCode) {
					if (this.language === '0') {
						this.$message.error("验证码错误")
					} else if (this.language === '1') {
						this.$message.error("Verification code error")
					}
					return
				}
				this.$postRequest('/web/saveMessage', {
					title: this.title,
					content: this.content,
					contact: this.email,
				}).then((res) => {
					if (res) {
						if (this.language === '0') {
							this.$message.success("留言成功")
						} else if (this.language === '1') {
							this.$message.success("Message succeeded")
						}
						this.title = ''
						this.content = ''
						this.email = ''
						this.code = ''
					}
				}).catch((error) => {
					console.log(error)
				})
			},
			//获取数据
			getData() {
				this.$postRequest('/web/sysconfig', {}).then((res) => {
					if (res) {
						this.detailItem = res.data
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
		// 计算属性
		computed: {

		},
		// 侦听器
		watch: {

		},
	}
</script>

<style lang="scss" scoped>
	.bottom-div-mobile {
		width: 100%;
		background-color: lightcoral;

		.main-content {
			padding: 20px;

			.img-logo {
				width: 150px;
				height: 50px;
			}

			.title1 {
				font-size: 18px;
				color: #FFFFFF;
				word-break: break-all;
			}

			.img-code {
				width: 150px;
				height: 150px;
			}

			/deep/ .el-input__inner {
				font-size: 16px;
				color: #FFFFFF;
				background: none;
				height: 60px;
				padding: 20px;
				border-color: rgba($color: #FFFFFF, $alpha: 0.1);
			}

			/deep/ .el-textarea__inner {
				font-size: 16px;
				color: #FFFFFF;
				background: none;
				height: 200px;
				padding: 20px;
				border-color: rgba($color: #FFFFFF, $alpha: 0.1);
			}

			/deep/ .el-input-group__append {
				background: none;
				border: none;
				padding: 0;
			}

			.btn-submit {
				width: 150px;
				line-height: 40px;
				text-align: center;
				background: linear-gradient(59deg, #1D9E9B 0%, #63AE3C 100%);
				opacity: 1;
				border-radius: 0px;
				font-size: 15px;
				color: white;
				-webkit-user-select: none;
				cursor: pointer;
			}
		}

		.line {
			width: 100%;
			height: 1px;
			background: rgba($color: #FFFFFF, $alpha: 0.1);
		}

		.copyright {
			line-height: 30px;
			text-align: center;
			font-size: 15px;
			color: #FFFFFF;
			letter-spacing: 2px;
			padding: 10px;
		}
	}

	.bottom-div {
		width: 100%;
		height: 808px;

		.main-content {
			padding: 60px 278px;

			.img-logo {
				width: 156px;
				height: 60px;
			}

			.title1 {
				font-size: 18px;
				color: #FFFFFF;
			}

			.title2 {
				line-height: 40px;
			}

			.img-code {
				width: 200px;
				height: 200px;
			}

			/deep/ .el-input__inner {
				font-size: 16px;
				color: #FFFFFF;
				background: none;
				height: 60px;
				padding: 20px;
				border-color: rgba($color: #FFFFFF, $alpha: 0.1);
			}

			/deep/ .el-textarea__inner {
				font-size: 16px;
				color: #FFFFFF;
				background: none;
				height: 200px;
				padding: 20px;
				border-color: rgba($color: #FFFFFF, $alpha: 0.1);
			}

			/deep/ .el-input-group__append {
				background: none;
				border: none;
				padding: 0;
			}

			.btn-submit {
				width: 180px;
				line-height: 50px;
				text-align: center;
				background: linear-gradient(59deg, #1D9E9B 0%, #63AE3C 100%);
				opacity: 1;
				border-radius: 0px;
				font-size: 16px;
				color: white;
				-webkit-user-select: none;
				cursor: pointer;
			}
		}

		.line {
			width: 100%;
			height: 1px;
			background: rgba($color: #FFFFFF, $alpha: 0.1);
		}

		.copyright {
			line-height: 80px;
			text-align: center;
			font-size: 18px;
			color: #FFFFFF;
			letter-spacing: 2px;
		}
	}
</style>
