<template>
	<el-config-provider :locale="locale">
		<router-view v-if="!$route.meta.keepAlive"></router-view>
		<router-view v-slot="{ Component }" v-if="$route.meta.keepAlive">
			<keep-alive>
				<component :is="Component" />
			</keep-alive>
		</router-view>
	</el-config-provider>
</template>

<script>
	let tid
	import {
		ElConfigProvider
	} from 'element-plus'

	// 中文包
	import zhCn from 'element-plus/lib/locale/lang/zh-cn'

	export default {
		components: {
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup() {
			// 刷新页面
			const fn = () => {
				location.reload()
			}

			// 切换为中文
			let locale = zhCn
			return {
				locale
			}
		},
		data() {
			return {
				beforeWidth: 0
			}
		},
		// html加载完成之前
		created() {
			let that = this
			if (!this._isMobile()) {
				window.addEventListener('resize', function() {
					clearTimeout(tid)
					tid = setTimeout(that.refresh, 300)
				}, false)
			}
			window.addEventListener('pageshow', function(e) {
				if (e.persisted) {
					clearTimeout(tid)
					tid = setTimeout(that.refresh, 300)
				}
			}, false)
			that.refresh()
		},
		// 事件方法执行
		methods: {
			_isMobile() {
				return navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
			},
			refresh() {
				let minWidth = 800
				let width = window.document.documentElement.getBoundingClientRect().width
				if (width < minWidth || this._isMobile()) {
					sessionStorage.setItem('mobile', '1')
					if (this.beforeWidth >= minWidth) {
						location.reload()
					}
				} else {
					sessionStorage.setItem('mobile', '0')
					if (this.beforeWidth !== 0 && this.beforeWidth < minWidth) {
						location.reload()
					}
				}
				this.beforeWidth = width
			}
		},
	}
</script>
