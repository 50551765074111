import axios from 'axios' // 一个基于 promise 的 HTTP 库   npm install axios即可安装
import Router from '../router'
import {
	ElMessage,
	ElLoading
} from 'element-plus' // 这里用的element-ui中的ElMessage消息提示插件

let loadingInstance = null; //加载动画

// 配置了两个拦截器
/* 拦截客户端请求的信息，比如说单点登录、JWT(JSON Web Token一个解决跨域身份验证的方案)，在登录成功之后，服务端就会返回一个token(一个很长的字符串)，后面每一次请求都会携带这个token，服务端就会知道你有没有登录了,即：
1、用户向服务器发送用户名和密码
2、验证服务器之后，会将当前信息(比如用户名，密码，登录时间等信息)保存在当前会话中
3、然后服务器会向用户返回一个session_id，session信息写入用户的cookie中
4、后续的请求，用户都会先从cookie中取出这个session_id一并发送到服务器
5、服务器收到之后，通过比对这个session_id之前保存的数据，确认用户的身份
*/
// axios.defaults.baseURL = 'http://192.168.6.9:8011' //测试服务器
axios.defaults.baseURL = 'https://www.j-onecapital.com' //正式服务器

// 每一次请求不可能都要手动添加一个参数吧，所以在这里定义一个拦截器，请求发送，在这里自动将请求拦截下来，自动的往请求里面加一个参数
axios.interceptors.request.use(config => {
	loadingInstance = ElLoading.service({
		fullscreen: true,
		text: '加载中...',
		background: 'rgba(0, 0, 0, 0.5)',
	})
	config.headers.common['WebLang'] = localStorage.getItem('language');
	return config
}, err => {
	if (loadingInstance) {
		loadingInstance.close()
	}
	ElMessage.error({
		message: '请求超时'
	})
	return Promise.resolve(err)
})
// 拦截服务端响应的信息
axios.interceptors.response.use(data => {
	if (loadingInstance) {
		loadingInstance.close()
	}
	if (data.status && data.status === 200 && data.data.code !== 200) {
		ElMessage.error({
			message: data.data.message
		})
		return
	}
	// 返回方法调用的那里，拿到的就是服务端返回的数据
	if (data.data) {
		return data.data
	}
}, err => {
	if (loadingInstance) {
		loadingInstance.close()
	}
	// 如果HTTP响应码是400,500等就会进入这个err函数中
	// 如果服务器没启动或者是路径错误进入此判断中
	if (!err.response) {
		ElMessage.error({
			message: err.message
		})
		return
	}
	if (err.response.status === 504 || err.response.status === 500 || err.response.status === 404) {
		ElMessage.error({
			message: '服务器连接失败'
		})
	} else if (err.response.status === 403) { // 表示权限不足
		ElMessage.error({
			message: '权限不足，请联系管理员'
		})
	} else if (err.response.status === 401) { // 表示未登录
		ElMessage.error({
			message: err.response.data.msg // 服务器返回来的信息
		})
	} else {
		if (err.response.data.msg) {
			ElMessage.error({
				message: err.response.data.msg
			})
		} else {
			ElMessage.error({
				message: '未知错误'
			})
		}
	}
})
// 登录接口
export const postKeyValueRequest = (url, params) => {
	return axios({
		method: 'post',
		url: `${url}`, // url的拼接，base就是我们定义的前缀，url就是你传进来的请求
		data: params,
		transformRequest: [function(data) {
			let ret = ''
			for (let it in data) {
				ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
			}
			return ret
		}],
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}
// get请求接口
export const getRequest = (url, params) => {
	let paramStr = ''
	for (let key of Object.keys(params)) {
		let name = params[key];
		if (paramStr === '') {
			paramStr += key + '=' + name
		} else {
			paramStr += '&' + key + '=' + name
		}
	}
	return axios({
		method: 'get',
		url: `${url}` + '?' + paramStr
	})
}
// post请求接口
export const postRequest = (url, params) => {
	return axios({
		method: 'post',
		url: `${url}`,
		data: params,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
// post的Form请求接口
export const postFormRequest = (url, params) => {
	return axios({
		method: 'post',
		url: `${url}`,
		data: params,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}
// 文件上传接口
export const uploadFileRequest = (url, params) => {
	return axios({
		method: 'post',
		url: `${url}`,
		data: params,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}
// put请求接口
export const putRequest = (url, params) => {
	return axios({
		method: 'put',
		url: `${url}`,
		data: params,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
// delete请求接口
export const deleteRequest = (url) => {
	return axios({
		method: 'delete',
		url: `${url}`
	})
}
