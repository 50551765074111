import {
	createStore
} from 'vuex'

export default createStore({
	state: { //存放组件之间共享的数据
		scrollTop: 0, //窗口滚动高度
		navItem: '', //当前顶部导航
	},
	mutations: { //显式的更改state里的数据，触发用this.$store.commit('test1',10)
		setScrollTop: function(state, a) {
			state.scrollTop = a
		},
		setNavItem: function(state, a) {
			state.navItem = a
		}
	},
	getters: { //获取数据的方法
		getScrollTop: function(state) {
			return state.scrollTop;
		},
		getNavItem: function(state) {
			return state.navItem;
		}
	},
	actions: { //异步执行的方法，触发用this.store.dispatch('test2', 5);
		//设置延时
		test2: function(context, value) {
			setTimeout(function() {
				//提交事件
				context.commit('test1', value);
			}, 1000)

		}
	}
});
