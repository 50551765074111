import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import defaultPage from '@/views/default.vue'

const routes = [{
	path: '/',
	name: 'defaultPage',
	redirect: '/home',
	component: defaultPage,
	children: [{
		path: '/home',
		name: 'home',
		component: () => import('../views/home.vue'),
		meta: {
			title: '首页',
			keepAlive: true,
		}
	}, {
		path: '/about',
		name: 'about',
		component: () => import('../views/about.vue'),
		meta: {
			title: '关于我们',
			keepAlive: true,
		}
	}, {
		path: '/business',
		name: 'business',
		component: () => import('../views/business.vue'),
		meta: {
			title: '业务介绍',
			keepAlive: true,
		}
	}, {
		path: '/case',
		name: 'case',
		component: () => import('../views/case.vue'),
		meta: {
			title: '投资案例',
			keepAlive: true,
		}
	}, {
		path: '/news',
		name: 'news',
		component: () => import('../views/news.vue'),
		meta: {
			title: '新闻资讯',
			keepAlive: true,
		}
	}, {
		path: '/contact',
		name: 'contact',
		component: () => import('../views/contact.vue'),
		meta: {
			title: '联系我们',
			keepAlive: true,
		}
	}, {
		path: '/friends',
		name: 'friends',
		component: () => import('../views/friends.vue'),
		meta: {
			title: '友情链接',
			keepAlive: true,
		}
	}, {
		path: '/partnerDetail',
		name: 'partnerDetail',
		component: () => import('../views/partner_detail.vue'),
		meta: {
			title: '投资者详情',
			keepAlive: false,
		}
	}]
}]
const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
