<template>
	<div class="main-page">
		<!--页面头部-->
		<Header></Header>
		<div ref="content" id="content" class="column-me flex1" style="overflow: auto;">
			<!-- 页面主内容 -->
			<div class="flex1">
				<!-- vue3.0配置 -->
				<router-view v-slot="{ Component }">
					<keep-alive>
						<component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path" />
					</keep-alive>
					<component :is="Component" v-if="!$route.meta.keepAlive" />
				</router-view>
			</div>
			<!--页面底部-->
			<Footer></Footer>
			<!-- 回到顶部 -->
			<el-backtop target="#content"></el-backtop>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	import Footer from '@/components/footer.vue';

	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {

			}
		},
		watch: {
			$route: {
				handler(to, from) {
					if (to.name != from.name) {
						if (to.name === 'about') { //解决锚点el-affix错位
							document.getElementById('content').scrollTop = 100
						}
						document.getElementById('content').scrollTop = 0
					}
				},
				// 深度观察监听
				deep: true
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.$bus.$on('scrollPage', res => {
					if (this.$refs.content) {
						let scrollTop = this.$refs.content.scrollTop
						let top = res.top + scrollTop - 180
						if (res.tabIndex === 0) {
							top -= 80
						}
						this.$refs.content.scroll({
							top: top,
							behavior: "smooth",
						})
					}
				})
				this.$bus.$on('scrollTo', res => {
					if (this.$refs.content) {
						this.$refs.content.scroll({
							top: res,
							behavior: "smooth",
						})
					}
				})
			})
			window.addEventListener('scroll', this.handleScroll, true)
		},
		methods: {
			//页面滚动
			handleScroll(e) {
				if (this.$refs.content) {
					let scrollTop = this.$refs.content.scrollTop
					// console.log("scrollTop:", scrollTop)
					this.$store.commit('setScrollTop', scrollTop)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
