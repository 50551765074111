<template>
	<div>
		<div id="headerDiv" class="header-bg header-mobile" v-if="isMobile">
			<div class="row-me row-center height100Per">
				<div class="row-me row-center mobile-title-div">
					<img src="../assets/img/logo.png" alt="" class="img-logo">
					<div class="mobile-title" @click="clickMenu">{{ mobileTitle }}</div>
					<div class="change-text" @click="clickEnglish">cn&nbsp;|&nbsp;en</div>
					<img src="../assets/img/menu.png" alt="" class="mobile-menu-img" @click="clickMenu">
				</div>
				<el-collapse-transition>
					<div class="column-me menu-div-mobile" v-show="showMenu">
						<div class="mobile-menu-item" v-for="(item,index) in titleArray" :key="index">
							<div @click.stop="clickTab(item,index)">
								{{ item.name }}
							</div>
						</div>
					</div>
				</el-collapse-transition>
			</div>
		</div>

		<div id="headerDiv" class="header" :class="{'header-bg':showBg}" v-else>
			<div class="row-me row-center height100Per margin-left100">
				<img src="../assets/img/logo.png" class="img-logo" draggable="false">
				<div class="row-me flex1 flex-end1 tab-div">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="item.name" :name="item.id" v-for="(item,index) in titleArray" :key="index">
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="change-text" @click="clickEnglish">cn&nbsp;|&nbsp;en</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {

		},
		data() {
			return {
				activeName: '',
				showBg: false,
				clientHeight: 0,
				titleArray: [],
				isMobile: '', //0电脑，1手机
				showMenu: false,
				mobileTitle: '',
			}
		},
		// html加载完成之前
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			let language = localStorage.getItem("language")
			if (!language || language === '') {
				localStorage.setItem("language", '0')
			}
			this.getData()
			this.$bus.$on('showBg', res => {
				this.showBg = res === '1'
			})
		},
		// html加载完成后执行。
		mounted() {
			this.$nextTick(() => {
				this.clientHeight = document.body.clientHeight
			})
		},
		// 事件方法执行
		methods: {
			clickEnglish() {
				let language = localStorage.getItem("language")
				if (language === '0') {
					localStorage.setItem('language', '1')
				} else {
					localStorage.setItem('language', '0')
				}
				location.reload()
			},
			clickMenu() {
				this.showMenu = !this.showMenu
			},
			handleClick(tab, event) {
				let navId = tab.props.name
				for (let title of this.titleArray) {
					if (title.id == navId) {
						this.$store.commit('setNavItem', title)
						this.$router.push({
							path: title.link_url
						})
					}
				}
			},
			clickTab(item, index) {
				let navId = item.id
				for (let title of this.titleArray) {
					if (title.id == navId) {
						this.$store.commit('setNavItem', title)
						this.$router.push({
							path: title.link_url
						})
					}
				}
				this.mobileTitle = item.name
				this.showMenu = false
			},
			getData() {
				this.$postRequest('/web/nav', {}).then((res) => {
					if (res) {
						this.titleArray = res.data
						if (this.titleArray.length > 0) {
							//获取当前路由
							let path = this.$route.path
							for (let title of this.titleArray) {
								if (title.link_url == path) {
									this.mobileTitle = title.name
									this.$store.commit('setNavItem', title)
									this.activeName = title.id
									document.title = title.name
								}
								if (title.link_url === '/about' && path === '/partnerDetail') { //投资者详情页
									this.$store.commit('setNavItem', title)
									this.activeName = title.id
									document.title = title.name
								}
							}
						}
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
		// 计算属性
		computed: {
			scrollTop() {
				return this.$store.state.scrollTop;
			}
		},
		// 侦听器
		watch: {
			$route: {
				handler(val, oldval) {
					let path = val.path
					for (let title of this.titleArray) {
						if (title.link_url == path) {
							this.$store.commit('setNavItem', title)
							this.activeName = title.id
						}
						if (title.link_url === '/about' && path === '/partnerDetail') { //投资者详情页
							this.$store.commit('setNavItem', title)
							this.activeName = title.id
						}
					}
				},
				deep: true
			},
			scrollTop: {
				handler: function(newVal, oldVal) {
					this.showMenu = false
					let path = this.$route.path
					if (path !== '/partnerDetail') {
						if (path === '/home') {
							this.showBg = newVal > this.clientHeight - 100
						} else {
							this.showBg = newVal > this.clientHeight - 300
						}
					}
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.header-bg {
		background-color: #1B3B4A !important;
	}

	.header-mobile {
		height: 60px;

		.mobile-title-div {
			width: 100%;
			height: 60px;

			.img-logo {
				width: 100px;
				height: 30px;
				margin-left: 10px;
				user-select: none;
				pointer-events: none;
			}

			.img-login {
				width: 60px;
				height: 25px;
				margin-right: 10px;
			}

			.mobile-logo-img {
				height: 30px;
				margin-left: 10px;
			}

			.change-text {
				font-size: 18px;
				color: #FFFFFF;
				margin-left: 10px;
				margin-right: 10px;
				cursor: pointer;

				&:hover {
					font-weight: bold;
				}
			}

			.mobile-menu-img {
				width: 50px;
				height: 100%;
				object-fit: scale-down;
			}

			.mobile-title {
				font-size: 20px;
				font-weight: bold;
				text-align: center;
				color: #FFFFFF;
				flex: 1;
			}
		}

		.menu-div-mobile {
			width: 150px;
			height: auto;
			position: fixed;
			z-index: 9999;
			top: 60px;
			right: 0;
			box-shadow: 0 0 10px #eeeeee;

			.mobile-menu-item {
				width: 100%;
				text-align: center;
				height: 50px;
				line-height: 50px;
				font-size: 20px;
				color: #333333;
				background: #FFFFFF;
				border-bottom: 1px solid #f6f6f6;
			}
		}
	}


	.header {
		background-color: rgba($color: #000000, $alpha: 0);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		width: 100%;
		height: 100px;
		z-index: 9999;

		&:hover {
			background-color: #1B3B4A;
		}

		.img-logo {
			width: 130px;
			height: 50px;
		}

		.tab-div {
			margin-bottom: -15px;
		}

		.change-text {
			font-size: 18px;
			color: #FFFFFF;
			margin-left: 50px;
			margin-right: 100px;
			cursor: pointer;

			&:hover {
				font-weight: bold;
			}
		}
	}
</style>
